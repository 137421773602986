import _ from "lodash";
import { Utils, Comparator } from "revlock-webutils";

export const Messages = {
    CONV_01: {
        type: "error",
        entityName: "OrderDetails",
        category: "TRAN",
        message: "OrderDetails Pre Conversion Record Was Filtered"
    },
    CONV_02: {
        type: "error",
        entityName: "BillingSchedule",
        category: "TRAN",
        message: "Billing Pre Conversion Record Was Filtered"
    },
    CONV_03: {
        type: "error",
        entityName: "ServiceDelivery",
        category: "TRAN",
        message: "ServiceDelivery Pre Conversion Record Was Filtered"
    },
    CONV_04: {
        type: "error",
        entityName: "Product",
        category: "TRAN",
        message: "Product currencies cannot be changed"
    },
    FOREX_01: {
        type: "error",
        entityName: "ForexRates",
        category: "TRAN",
        message: "Missing Date for these forex rows."
    },
    FOREX_02: {
        type: "error",
        entityName: "ForexRates",
        category: "TRAN",
        message: "Missing Currency for these forex rows."
    },
    FOREX_03: {
        type: "error",
        entityName: "ForexRates",
        category: "TRAN",
        message: "Missing Factor for these forex rows."
    },
    TRAN_01: {
        type: "error",
        category: "TRAN",
        message:
            "Double check the order date on these items, they are either missing or are incorrect.",
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_01_${entityId}`,
        specificMessage: (data) =>
            `Order date for sales order [${
                data.salesOrderId
            }] is ${(data.orderDate && "missing") || "incorrect"}.`,
        collectData: ({ salesOrder }) => ({ orderDate: salesOrder.orderDate })
    },
    TRAN_02: {
        type: "warning",
        category: "TRAN",
        message:
            "Double check the order date on these items. They seem like they are in the future.",
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_02_${entityId}`,
        specificMessage: (data) =>
            `Order date for sales order [${
                data.salesOrderId
            }] is ${(data.orderDate && "missing") || "incorrect"}.`,
        collectData: ({ salesOrder }) => ({ orderDate: salesOrder.orderDate })
    },
    TRAN_03: {
        type: "error",
        category: "TRAN",
        message:
            "Double check the start date on these order items, they appear incorrect.",
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_02_${entityId}`,
        specificMessage: (data) =>
            `Order date for sales order [${
                data.salesOrderId
            }] is ${(data.orderDate && "missing") || "incorrect"}.`,
        collectData: ({ salesOrder }) => ({ orderDate: salesOrder.orderDate })
    },
    TRAN_04: {
        type: "error",
        category: "TRAN",
        message:
            "Double check the end date on these order items, they appear incorrect.",
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_02_${entityId}`,
        specificMessage: (data) =>
            `Order date for sales order [${
                data.salesOrderId
            }] is ${(data.orderDate && "missing") || "incorrect"}.`,
        collectData: ({ salesOrder }) => ({ orderDate: salesOrder.orderDate })
    },
    TRAN_12: {
        type: "error",
        category: "TRAN",
        message:
            "Double check the billing date on these order items, they appear incorrect.",
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_02_${entityId}`,
        specificMessage: (data) =>
            `Billing date for sales order [${data.salesOrderId}] is ${data.billingDate} ' incorrect'}.`,
        collectData: ({ salesOrder }) => ({ orderDate: salesOrder.orderDate })
    },
    TRAN_05: {
        type: "error",
        category: "TRAN",
        message:
            "These orders have line items with missing or invalid quantity.",
        specificMessage: (data) =>
            `Sales Order [${data.salesOrderId}] has item [${data.salesOrderItemId}] with missing or invalid quantity`,
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_05_${data.salesOrderItemId}`
    },
    TRAN_06: {
        type: "error",
        category: "TRAN",
        message: "Missing or bad Unit Price for these items.",
        specificMessage: (data) =>
            `Sales Order [${data.salesOrderId}] has item [${data.salesOrderItemId}] with bad or missing unit price [${data.salePrice}]`,
        collectData: ({ salesOrderItem }) => ({
            salePrice: salesOrderItem.salePrice
        }),
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_06_${data.salesOrderItemId}`
    },
    TRAN_07: {
        type: "error",
        category: "TRAN",
        message: "Missing or bad List Price for these items.",
        specificMessage: (data) =>
            `Sales Order [${data.salesOrderId}] has item [${data.salesOrderItemId}] with bad or missing list price [${data.listPrice}]`,
        collectData: ({ salesOrderItem }) => ({
            listPrice: salesOrderItem.listPrice
        }),
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_07_${data.salesOrderItemId}`
    },
    TRAN_08: {
        type: "error",
        category: "TRAN",
        message:
            "You tried to update an invoice that was processed in a closed period.",
        collectData: ({ row, businessKey, currentPeriod }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"],
            billingDate: row["Billing Date"],
            currentPeriod,
            amount: row["Amount"],
            businessKey: businessKey,
            ..._.pick(row, businessKey || [])
        }),
        specificMessage: (data) => {
            let bsLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) =>
                        (bsLabel += `${key} [${Utils.shortString(
                            data[key]
                        )}], `)
                );

            if (!data.salesOrderId || !data.salesOrderItemId) {
                return `BillingSchedule item discarded for ${bsLabel} Billing Date [${data.billingDate}] Amount [${data.amount}].`;
            } else {
                return `BillingSchedule item discarded for Order Number [${data.salesOrderId}] Order Item Number [${data.salesOrderItemId}] ${bsLabel} Billing Date [${data.billingDate}] Amount [${data.amount}].`;
            }
        },
        entityName: "BillingSchedule",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) => {
            let uniqKey = `Billing_${entityId}_TRAN_08`;
            const invoiceNumber =
                (data.invoiceNumber && `${data.invoiceNumber}_`) || "";
            const billingDate = `${data.billingDate}_`;
            const customerId = (data.customerId && `${data.customerId}_`) || "";
            const salesOrderItemId =
                (data.salesOrderItemId && `${data.salesOrderItemId}_`) || "";

            uniqKey +=
                invoiceNumber + billingDate + customerId + salesOrderItemId;

            return uniqKey;
        }
    },
    TRAN_09: {
        type: "error",
        category: "TRAN",
        message:
            "No sales order items on this sales order. It will be ignored.",
        specificMessage: (data) =>
            `No sales order items on sales order [${data.salesOrderId}]`,
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_09_${data.salesOrderId}`
    },
    TRAN_10: {
        type: "error",
        category: "TRAN",
        message:
            'No "Order Number" specified for this sales order. It will be ignored.',
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) => `OrderDetails_TRAN_10`
    },
    TRAN_11: {
        type: "error",
        category: "TRAN",
        message:
            "Was unable to find the SSP, for these items. You can add them by clicking on the Standalone Selling Price.",
        specificMessage: (data) =>
            `Was unable to find SSP for product [${data.productCode}] in sales order [${data.salesOrderId}].`,
        collectData: ({ salesOrder, salesOrderItem }) => {
            return Object.assign(
                {},
                salesOrderItem.attributes,
                salesOrder.orderAttributes
            );
        },
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_11_${data.salesOrderItemId}`
    },
    TRAN_13: {
        type: "error",
        category: "TRAN",
        message: "Invalid Professional Service Delivery Item.",
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"],
            period: row["Period"],
            unitDelivered: row["Units Delivered"],
            percentComplete: row["Percent Complete"]
        }),
        specificMessage: (data) =>
            `Professional Service Delivery log discarded for Order Number [${data.salesOrderId}], Order Item Number [${data.salesOrderItemId}] and period [${data.period}].`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${entityId}_TRAN_13_${data.salesOrderItemId}`
    },
    TRAN_14: {
        type: "error",
        category: "TRAN",
        message: "Invalid Commission Item.",
        collectData: ({ row }) =>
            Utils.prune({
                salesOrderId: row["Order Number"],
                salesOrderItemId: row["Order Item Number"],
                commissionPercentage: row["Commission Percentage"],
                commissionExpense: row["Commission Expense"],
                salesPerson: row["Sales Person"] || row["Account Owner"],
                class: row["Sales Person"] || row["Class"]
            }),
        specificMessage: (data) =>
            `Invalid commission data found for Order Number [${
                data.salesOrderId
            }] Order Item Number [${data.salesOrderItemId}], Sales Person [${
                data.salesPerson
            }], Commission Percentage [${data.commissionPercentage ||
                data.orderCommissionPercentage}], Commission Expense [${data.commissionExpense ||
                data.orderCommissionExpense}]`,
        entityName: "Commissions",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `Commission_${entityId}_TRAN_14_${data.salesOrderItemId}`
    },
    TRAN_15: {
        type: "error",
        category: "orders",
        message:
            "We can't process salesOrder because it has an invalid duration",
        specificMessage: (data) =>
            `Sales order [${data.salesOrderId}] has item [${data.salesOrderItemId}] with more than ${data.maxContractYears} years duration.`,
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_15_${data.salesOrderItemId}`,
        collectData: ({ maxContractYears }) => ({
            maxContractYears
        })
    },
    TRAN_16: {
        type: "warning",
        category: "orders",
        message: "We can't find Delivery End Date",
        specificMessage: (data) =>
            `Sales order [${data.salesOrderId}] having item [${data.salesOrderItemId}] doesn't have Delivery End Date.`,
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_16_${data.salesOrderItemId}`
    },
    TRAN_18: {
        type: "error",
        category: "orders",
        message:
            "We can't process salesOrder because it has an invalid duration",
        specificMessage: (data) =>
            `Sales order [${data.salesOrderId}] has item [${data.salesOrderItemId}] with less or equals to 0 duration.`,
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_18_${data.salesOrderItemId}`
    },
    TRAN_19: {
        type: "error",
        category: "orders",
        message:
            "We can't process salesOrder because it has duplicate order item numbers",
        specificMessage: (data) =>
            `Sales order [${data.salesOrderId}] has item [${data.salesOrderItemId}] which is a duplicate entry.`,
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_19_${data.salesOrderItemId}`
    },
    TRAN_20: {
        type: "error",
        category: "TRAN",
        message:
            "We found ServiceDelivery log records that map to more than one sales order item.",
        collectData: ({ row, orderDetails, businessKey }) => ({
            period: row["Period"],
            unitsDelivered: row["Units Delivered"],
            percentComplete: row["Percent Complete"],
            costIncurred: row["Cost Incurred"],
            costType: row["Cost Incurred"],
            businessKey,
            ..._.pick(row, businessKey),
            ...Utils.arrayToObject(
                orderDetails,
                (orderDetail, index) => `Order ${index + 1}`,
                (orderDetail) =>
                    orderDetail["Order Number"] ||
                    orderDetail["salesOrderId"] ||
                    orderDetail["id"]
            ),
            salesOrderItemId:
                row["Order Item Number"] ||
                orderDetails[0]["Order Item Number"] ||
                orderDetails[0]["salesOrderItemId"]
        }),
        specificMessage: (data) => {
            let psdLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) =>
                        (psdLabel += `${key} [${Utils.shortString(
                            data[key]
                        )}], `)
                );

            return `ServiceDelivery log discarded for ${psdLabel} period [${data.period}].`;
        },
        entityName: "ServiceDelivery",
        getEntityId: (data) => {
            let psdLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) => (psdLabel += `${key} [${data[key]}], `)
                );
            return data.salesOrderId || psdLabel;
        },
        getUniqKey: (data, entityId) => {
            let psdLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) => (psdLabel += `${key} [${data[key]}], `)
                );

            let toReturn = `ServiceDelivery_${entityId}_TRAN_20_`;
            const itemId = data.salesOrderItemId || psdLabel;

            return `${toReturn}${itemId}`;
        }
    },
    TRAN_21: {
        type: "error",
        category: "TRAN",
        message: "Mismatch reference data currency with sales order currency.",
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_21_${entityId}`
    },
    TRAN_22: {
        type: "error",
        category: "TRAN",
        message:
            "No matching data found for Professional Service Delivery Item",
        collectData: ({ row, businessKey }) => ({
            period: row["Period"],
            unitsDelivered: row["Units Delivered"],
            percentComplete: row["Percent Complete"],
            costIncurred: row["Cost Incurred"],
            costType: row["Cost Incurred"],
            businessKey,
            ..._.pick(row, businessKey),
            ..._.pick(row, ["Order Number", "Order Item Number"])
        }),
        specificMessage: (data) =>
            `Professional Service Delivery log discarded as no matching sales order was found.`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => {
            let psdLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) => (psdLabel += `${key} [${data[key]}], `)
                );
            return data.salesOrderId || data["Order Number"] || psdLabel;
        },
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${entityId}_TRAN_22_${data.salesOrderItemId ||
                data["Order Item Number"] ||
                entityId}`
    },
    TRAN_23: {
        type: "error",
        category: "TRAN",
        message:
            "We found Billing Schedule records that map to more than one sales order.",
        collectData: ({ row, businessKey, orderDetails }) => ({
            billingDate: row["Billing Date"],
            amount: row["Amount"],
            businessKey,
            ..._.pick(row, businessKey),
            ...Utils.arrayToObject(
                orderDetails,
                (orderDetail, index) => `Order ${index + 1}`,
                (orderDetail) =>
                    orderDetail["Order Number"] || orderDetail["id"]
            )
        }),
        specificMessage: (data) => {
            let bsLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) =>
                        (bsLabel += `${key} [${Utils.shortString(
                            data[key]
                        )}], `)
                );

            return `BillingSchedule log discarded for ${bsLabel} period [${data.billingDate}] and amount [${data.amount}].`;
        },
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            let bsLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) => (bsLabel += `${key} [${data[key]}], `)
                );

            return data.salesOrderId || bsLabel;
        },
        getUniqKey: (data, entityId) => {
            let bsLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) => (bsLabel += `${key} [${data[key]}], `)
                );

            let toReturn = `Billing_${entityId}_TRAN_23_`;
            const itemId = bsLabel || data.salesOrderItemId;

            return `${toReturn}${itemId}`;
        }
    },
    TRAN_25: {
        type: "warning",
        category: "TRAN",
        message: "Invalid Professional Service Delivery Item",
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        specificMessage: (data) =>
            `Professional Service Delivery log adjusted from [${data.unitsDelivered}] to [${data.quantity}] for period [${data.period}]. Total extra units sold [${data.extraUnits}]`,
        collectData: ({
            period,
            actualUnitsSold,
            unitsLogPosted,
            extraUnits
        }) => ({
            unitsDelivered: unitsLogPosted,
            quantity: actualUnitsSold,
            period,
            extraUnits
        })
    },
    TRAN_26: {
        type: "error",
        category: "TRAN",
        message: "Duplicate Professional Service Delivery log Item.",
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"],
            period: row["Period"],
            quantityDelivered: row["Units Delivered"] || row["Percent Complete"]
        }),
        specificMessage: (data) =>
            `Professional Service Delivery log discarded for Order Number [${data.salesOrderId}], Order Item Number [${data.salesOrderItemId}], period [${data.period}] and Quantity Delievered [${data.quantityDelivered}].`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${entityId}_TRAN_26_${data.salesOrderItemId}`
    },
    TRAN_27: {
        type: "error",
        category: "TRAN",
        message: "No matching data found for BillingSchedule item",
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            let toReturn = data.salesOrderId || data.invoiceNumber;
            const billingDate = data.billingDate;
            if (!toReturn) {
                toReturn = billingDate;
            }

            return toReturn || "";
        },
        getUniqKey: (data, entityId) => {
            let uniqKey = `Billing_${entityId}_TRAN_27_`;
            const invoiceNumber =
                (data.invoiceNumber && `${data.invoiceNumber}_`) || "";
            const billingDate = `${data.billingDate}_`;
            const customerId = (data.customerId && `${data.customerId}_`) || "";
            const salesOrderItemId =
                (data.salesOrderItemId && `${data.salesOrderItemId}`) || "";

            uniqKey +=
                invoiceNumber + billingDate + customerId + salesOrderItemId;

            return uniqKey;
        },
        collectData: ({ row, businessKey }) =>
            Utils.prune({
                billingDate: row["Billing Date"],
                amount: row["Amount"],
                customerId: row["Customer Id"],
                customerName: row["Customer Name"],
                businessKey,
                ..._.pick(row, businessKey || []),
                ..._.pick(row["customFields"], ["invoiceNumber"]),
                factAmount: row["Amount"],
                salesOrderId: row["Order Number"] || "",
                salesOrderItemId: row["Order Item Number"] || ""
            }),
        specificMessage: (data) => {
            let bsLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) =>
                        (bsLabel += `${key} [${Utils.shortString(
                            data[key]
                        )}], `)
                );
            return `BillingSchedule log discarded for ${(data.row &&
                data.row["Order Number"]) ||
                ""} ${(data.row && data.row["Order Item Number"]) ||
                ""} ${bsLabel} period [${data.billingDate}] and amount [${
                data.amount
            }].`;
        }
    },
    TRAN_28: {
        type: "error",
        category: "TRAN",
        message: "Invalid Custom Fields Data provided.",
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"]
        }),
        specificMessage: (data) =>
            `Invalid custom fields data provided for Order Number [${data.salesOrderId}] Order Item Number [${data.salesOrderItemId}].`,
        entityName: "CustomFields",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `Customer Fields_${entityId}_TRAN_28_${data.salesorderItemId ||
                entityId}`
    },
    TRAN_29: {
        type: "error",
        category: "TRAN",
        message: "Unable to read excel file(s)",
        collectData: ({ row }) => ({
            filename: row["filename"],
            drivername: row["Drivername"]
        }),
        specificMessage: (data) =>
            `${data.drivername} - Unable to read source file ${data.filename}, please re-upload the file and try again.`,
        entityName: "Inference",
        getEntityId: (data) => data.filename,
        getUniqKey: (data) =>
            `Inference_${data.filename}_TRAN_29_${data.filename}`
    },
    TRAN_30: {
        type: "error",
        category: "TRAN",
        message: "Inactive sales order item.",
        specificMessage: (data) => {
            // tell user about the derefrenced field that needs to be set for item to become active.
            return `Product [${data.product}] is marked inactive, please provide ${data.dereferencedStartDate} & ${data.dereferencedEndDate}.`;
        },
        entityName: "OrderDetails",
        getEntityId: (data) => {
            let toReturn = data.salesOrderId || data.salesOrderItemId;
            return toReturn;
        },
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_TRAN_30_${data.salesOrderItemId ||
                entityId}`,
        collectData: ({ salesOrder, salesOrderItem, customFields }) => {
            let toReturn = {
                id: salesOrderItem.rootId || salesOrderItem.id,
                productCode: salesOrderItem.product.code
            };

            // Start date and end date can both be dereferenced.
            toReturn.dereferencedStartDate = "Delivery Start Date";
            toReturn.dereferencedEndDate = "Delivery End Date";

            if (salesOrderItem.dereferencedStartDate !== "manualDate") {
                Object.values(customFields).forEach((customField) => {
                    if (
                        customField.name ===
                        salesOrderItem.dereferencedStartDate
                    ) {
                        toReturn["dereferencedStartDate"] =
                            customField.displayName;
                    }
                });
            }

            // In case end date is deferenced add it to the error data.
            if (salesOrderItem.dereferencedEndDate !== "manualDate") {
                Object.values(customFields).forEach((customField) => {
                    if (
                        customField.name === salesOrderItem.dereferencedEndDate
                    ) {
                        toReturn["dereferencedEndDate"] =
                            customField.displayName;
                    }
                });
            }

            toReturn.salesOrderId = salesOrder.id;

            return toReturn;
        }
    },
    TRAN_31: {
        type: "error",
        category: "TRAN",
        message: "Bad amount on this invoice, it will be ignored.",
        specificMessage: (data) => {
            let bsLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) =>
                        (bsLabel += `${key} [${Utils.shortString(
                            data[key]
                        )}], `)
                );
            if (!data.salesOrderId || !data.salesOrderItemId) {
                return `BillingSchedule item discarded for ${bsLabel} Billing Date [${data.billingDate}] Amount [${data.amount}].`;
            } else {
                return `BillingSchedule item discarded for Order Number [${data.salesOrderId}] Order Item Number [${data.salesOrderItemId}] ${bsLabel} Billing Date [${data.billingDate}] with Bad amount.`;
            }
        },
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            return data && data.row ? data.row["Invoice Number"] : "";
        },
        getUniqKey: (data, entityId) => {
            let uniqKey = `Billing_${entityId}_TRAN_31`;
            const invoiceNumber =
                (data.invoiceNumber && `${data.invoiceNumber}_`) || "";
            const billingDate = `${data.billingDate}_`;
            const customerId = (data.customerId && `${data.customerId}_`) || "";
            const salesOrderItemId =
                (data.salesOrderItemId && `${data.salesOrderItemId}_`) || "";

            uniqKey +=
                invoiceNumber + billingDate + customerId + salesOrderItemId;

            return uniqKey;
        }
    },
    TRAN_32: {
        type: "error",
        category: "TRAN",
        message: "Invalid Professional Service Delivery Item.",
        specificMessage: (data) =>
            `Professional Service Delivery log discarded on line [${data.rowNum}] due to missing data.`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.rowNum,
        getUniqKey: (data) => `ServiceDelivery_Log_TRAN_32_${data.rowNum}`
    },
    TRAN_33: {
        type: "error",
        category: "TRAN",
        message: "Customer currency does not match with BillingSchedule",
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            return data && data.row ? data.row["Invoice Number"] : "";
        }
    },
    TRAN_34: {
        type: "error",
        category: "TRAN",
        message:
            "Multi currency feature is enabled. Order Number is a required to process the invoice.",
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            return data && data.row ? data.row["Invoice Number"] : "";
        },
        specificMessage: (data) =>
            `Multi currency feature is enabled. Order Number is a required field to process the invoice [${data.invoiceNumber}].`,
        collectData: ({ row }) => ({
            invoiceNumber: row["Invoice Number"]
        })
    },
    TRAN_35: {
        type: "error",
        category: "TRAN",
        message:
            "Invalid Order: special characters not allowed in Order Number.",
        entityName: "OrderDetails",
        getEntityId: (data) => data && data.salesOrderId,
        getUniqKey: (data, entityId) => `OrderDetails_TRAN_10`
    },
    TRAN_36: {
        type: "error",
        category: "TRAN",
        message: "Invoice number is missing.",
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            return data && data.row ? data.row["Order Number"] : "";
        }
    },
    TRAN_37: {
        type: "error",
        category: "TRAN",
        message:
            "BillingSchedule items for this Sales Order exceeds 2,000 items per Sales Order",
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            let toReturn = data.salesOrderId || data.invoiceNumber;
            const billingDate = data.billingDate;
            if (!toReturn) {
                toReturn = billingDate;
            }

            return toReturn || "";
        },
        getUniqKey: (data, entityId) => {
            let uniqKey = `Billing_${entityId}_TRAN_37_`;
            const invoiceNumber =
                (data.invoiceNumber && `${data.invoiceNumber}_`) || "";
            const billingDate = `${data.billingDate}_`;
            const customerId = (data.customerId && `${data.customerId}_`) || "";
            const salesOrderItemId =
                (data.salesOrderItemId && `${data.salesOrderItemId}`) || "";

            uniqKey +=
                invoiceNumber + billingDate + customerId + salesOrderItemId;

            return uniqKey;
        },
        collectData: ({ row, businessKey }) =>
            Utils.prune({
                billingDate: row["Billing Date"],
                amount: row["Amount"],
                customerId: row["Customer Id"],
                customerName: row["Customer Name"],
                businessKey,
                ..._.pick(row, businessKey || []),
                ..._.pick(row["customFields"], ["invoiceNumber"]),
                factAmount: row["Amount"],
                salesOrderId: row["Order Number"] || "",
                salesOrderItemId: row["Order Item Number"] || ""
            }),
        specificMessage: (data) => {
            let bsLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) =>
                        (bsLabel += `${key} [${Utils.shortString(
                            data[key]
                        )}], `)
                );
            return `BillingSchedule log discarded for ${(data.row &&
                data.row["Order Number"]) ||
                ""} ${(data.row && data.row["Order Item Number"]) ||
                ""} ${bsLabel} period [${data.billingDate}] and amount [${
                data.amount
            }].`;
        }
    },
    TRAN_38: {
        type: "error",
        category: "TRAN",
        message:
            "More than 2000 billing schedule per sales order is not supported.",
        specificMessage: (data) => {
            let bsLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) =>
                        (bsLabel += `${key} [${Utils.shortString(
                            data[key]
                        )}], `)
                );
            if (!data.salesOrderId || !data.salesOrderItemId) {
                return `BillingSchedule item discarded for ${bsLabel} Billing Date [${data.billingDate}] Amount [${data.amount}], as total billing schedule items per salesOrder exceeds 2000 limit.`;
            } else {
                return `BillingSchedule item discarded for Order Number [${data.salesOrderId}] Order Item Number [${data.salesOrderItemId}] ${bsLabel} Billing Date [${data.billingDate}], as total billing schedule items per salesOrder exceeds 2000 limit.`;
            }
        },
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            return data && data.row ? data.row["Invoice Number"] : "";
        },
        getUniqKey: (data, entityId) => {
            let uniqKey = `Billing_${entityId}_TRAN_38`;
            const invoiceNumber =
                (data.invoiceNumber && `${data.invoiceNumber}_`) || "";
            const billingDate = `${data.billingDate}_`;
            const customerId = (data.customerId && `${data.customerId}_`) || "";
            const salesOrderItemId =
                (data.salesOrderItemId && `${data.salesOrderItemId}_`) || "";

            uniqKey +=
                invoiceNumber + billingDate + customerId + salesOrderItemId;

            return uniqKey;
        }
    },
    TRAN_39: {
        type: "error",
        category: "TRAN",
        message:
            "Invalid Order Number - Order number provided for Invoice does not belong to this customer.",
        entityName: "BillingSchedule",
        specificMessage: (data) =>
            `Invalid Order Number - Order number provided for Invoice [${data.invoiceNumber}] does not belong to this customer.`,
        collectData: ({ row }) => ({
            invoiceNumber: row["Invoice Number"]
        })
    },
    TRAN_40: {
        type: "error",
        category: "TRAN",
        message:
            "Invalid Reference Invoice Number - Reference Invoice Number provided for Invoice does not belong to this customer",
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            return data && data.row ? data.row["Invoice Number"] : "";
        },
        specificMessage: (data) =>
            `Invalid Reference Invoice Number - Reference Invoice Number [${data["Reference Invoice Number"]}] provided for Invoice [${data.invoiceNumber}] does not belong to this customer.`,
        collectData: ({ row }) => ({
            invoice_number: row["Invoice Number"],
            customerId: row["Customer Id"]
        })
    },
    TRAN_41: {
        type: "error",
        category: "TRAN",
        message: "These orders only have Discount amount.",
        specificMessage: (data) =>
            `Order ${data.salesOrderId} only have Discount amount.`,
        entityName: "OrderDetails",
        getEntityId: (data) => data && data.salesOrderId,
        getUniqKey: (data, entityId) => `OrderDetails_TRAN_41`
    },
    TRAN_42: {
        type: "error",
        category: "TRAN",
        message:
            "Order Item Number is required when deferred by item is enabled.",
        entityName: "BillingSchedule",
        getEntityId: (data) => data && data.salesOrderId,
        specificMessage: (data) =>
            `Order Item Number is required when deferred by item is enabled for order [${data.salesOrderId}].`,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"]
        })
    },
    TRAN_43: {
        type: "error",
        category: "TRAN",
        message:
            "Cannot update invoices when sync is disabled for it's linked Order Number.",
        entityName: "BillingSchedule",
        getEntityId: (data) => data && data.salesOrderId,
        specificMessage: (data) =>
            `Order [${data.salesOrderId}] has sync disabled so rejecting the updates for invoices.`,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"]
        })
    },
    TRAN_44: {
        type: "error",
        category: "TRAN",
        message:
            "Service End Date is required for Professional Service Delivery if the Delivery Recognition Method is Ratable",
        collectData: (data) => ({
            salesOrderId: data.salesOrderId,
            salesOrderItemId: data.salesOrderItemId,
            quantityDelivered: data.quantityDelivered,
            logDate: data.logDate
        }),
        specificMessage: (data) =>
            `Professional Service Delivery log discarded for Order Number [${data.salesOrderId}], Order Item Number [${data.salesOrderItemId}], period [${data.logDate}] and Quantity Delievered [${data.quantityDelivered}].`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${entityId}_TRAN_44_${data.salesOrderItemId}`
    },
    TRAN_45: {
        type: "warning",
        category: "TRAN",
        message:
            "Service Delivery log cannot be assigned to any Sales Order Item",
        collectData: (data) => ({
            salesOrderId: data.salesOrderId,
            salesOrderItemId: data.salesOrderItemId,
            unitsDelivered: data.unitsDelivered,
            logDate: data.logDate,
            serviceDeliveryId: data.serviceDeliveryId
        }),
        specificMessage: (data) =>
            `Service Delivery log discarded for Order Number [${data.salesOrderId}], Order Item Number [${data.salesOrderItemId}], period [${data.logDate}] and Quantity Delievered [${data.unitsDelivered}].`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${entityId}_TRAN_45_${data.salesOrderItemId}`
    },
    TRAN_46: {
        type: "error",
        category: "TRAN",
        message: "Product Code is required for Service Delivery",
        collectData: ({ row, businessKey }) => ({
            period: row["Period"],
            serviceDeliveryId: row["Service Delivery Id"],
            unitsDelivered: row["Units Delivered"],
            percentComplete: row["Percent Complete"],
            businessKey,
            ..._.pick(row, businessKey),
            ..._.pick(row, ["Order Number", "Order Item Number"])
        }),
        specificMessage: (data) =>
            `Professional Service Delivery log discarded as Product Code is missing.`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => {
            let psdLabel = "";
            data.businessKey &&
                data.businessKey.forEach(
                    (key) => (psdLabel += `${key} [${data[key]}], `)
                );
            return data.salesOrderId || data["Order Number"] || psdLabel;
        },
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${entityId}_TRAN_46_${data[
                "Service Delivery Id"
            ] ||
                data.logDate ||
                entityId}`
    },
    TRAN_47: {
        type: "error",
        category: "TRAN",
        message: "Billing date is either missing or incorrect.",
        entityName: "BillingSchedule",
        getEntityId: (data) => {
            return data && data.invoiceNumber;
        },
        specificMessage: (data) =>
            `Billing date [${data.billingDate}] is either missing or incorrect for the Billing [${data.invoiceNumber}]`,
        collectData: ({ row }) => ({
            invoiceNumber: row["Invoice Number"],
            billingDate: row["Billing Date"]
        })
    },
    TRAN_48: {
        type: "error",
        category: "TRAN",
        message: "Invalid transaction date.",
        entityName: "OrderDetails",
        getEntityId: (data) => {
            return data && data.row ? data.row["Transaction Date"] : "";
        }
    },
    TRAN_49: {
        type: "error",
        category: "TRAN",
        message: "Invalid Modification Type.",
        entityName: "OrderDetails",
        getEntityId: (data) => {
            return data && data.salesOrderId;
        }
    },
    TRAN_50: {
        type: "error",
        category: "TRAN",
        message: "Invalid Modification Date.",
        entityName: "OrderDetails",
        getEntityId: (data) => {
            return data && data.salesOrderId;
        }
    },
    TRAN_51: {
        type: "error",
        category: "TRAN",
        message:
            "Modification Type should be same for all the line items in a Sales Order.",
        entityName: "OrderDetails",
        getEntityId: (data) => {
            return data && data.salesOrderId;
        }
    },
    TRAN_52: {
        type: "error",
        category: "TRAN",
        message:
            "Modification Date should be same for all the line items in a Sales Order.",
        entityName: "OrderDetails",
        getEntityId: (data) => {
            return data && data.salesOrderId;
        }
    },
    TRAN_53: {
        type: "error",
        category: "TRAN",
        message:
            "Product code in the BillingSchedule does not match with the OrderDetails",
        entityName: "BillingSchedule",
        getEntityId: (data) => data && data.invoiceNumber,
        specificMessage: (data) =>
            `Product code in the billing [${data.invoiceNumber}] does not match with the order [${data.salesOrderId}].`,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            invoiceNumber: row["Invoice Number"]
        })
    },
    REF_01: {
        type: "warning",
        category: "REF",
        message: "We got a product code / SKU that we don't recognize.",
        specificMessage: (data) => {
            if (data["row"]["Product Code"])
                return `Unknown product code provided [${data["row"]["Product Code"]}] for sales order item [${data.salesOrderItemId}] in sales order [${data.salesOrderId}]. RevLock would add a new product.`;
            else
                return `Invalid or missing product code for sales order item [${data.salesOrderItemId}] in sales order [${data.salesOrderId}]`;
        },
        entityName: "OrderDetails",
        getEntityId: (data) => "MISSING_PRODUCT_CODE",
        getUniqKey: (data, entityId) => `OrderDetails_MISSING_PRODUCT_CODE`
    },
    REF_02: {
        type: "warning",
        category: "REF",
        message:
            "We can't find the Customer for these transactions. You can add it by clicking on Customers.",
        specificMessage: (data) =>
            `Unknown or missing customer [${data.customerId}] for salesOrder [${data.salesOrderId}]`,
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_REF_02_${entityId}`
    },
    REF_03: {
        type: "warning",
        category: "REF",
        message:
            "We can't find the Sales person for these transactions. You can add it by clicking on Settings -> Salesperson.",
        specificMessage: (data) => {
            if (data.salesPersonId) {
                return `A previously unseen sales person [${
                    data.salesPersonId
                } ${
                    data.salesPersonName ? `(${data.salesPersonName})` : ""
                }] found for salesOrder [${
                    data.salesOrderId
                }]. RevLock will add a new sales person.`;
            } else {
                return `Invalid or missing Sales Person for Sales Order [${data.salesOrderId}]. RevLock would use DEFAULT value.`;
            }
        },
        collectData: ({ salesOrder, row }) => ({
            salesPersonId: salesOrder.salesPersonId,
            salesPersonName: row && row["Account Owner"] && row["Account Owner"]
        }),
        entityName: "SalesPerson",
        getEntityId: (data) => {
            // thing is this error is raised from both XLSynDriver as well as from SalesOrderVisitor
            return (
                data.salesPersonId ||
                data.salesPersonName ||
                data.salesOrderId ||
                ""
            );
        },
        getUniqKey: (data, entityId) =>
            `Sales Person_${entityId}_REF_03_${entityId}`
    },
    REF_CUSTOMER_01: {
        type: "error",
        category: "REF",
        message:
            "The customer record we found doesn't have a customer identifier on it. Correct and reload it.",
        entityName: "Customer",
        collectData: ({ row }) => ({
            customerId:
                row["Customer Id"] ||
                row["Customer ID"] ||
                row["Account Id"] ||
                row["Account ID"],
            customerName: row["Customer Name"]
        }),
        getEntityId: (data) => data.customerId || data.customerName,
        getUniqKey: (data, entityId) =>
            `Customer_${entityId}_REF_CUSTOMER_01_${entityId}`
    },
    REF_CUSTOMER_02: {
        type: "error",
        category: "REF",
        message:
            "The customer record we found doesn't have a name on it. Correct and reload it.",
        entityName: "Customer",
        collectData: ({ row }) => ({
            customerId:
                row["Customer Id"] ||
                row["Customer ID"] ||
                row["Account Id"] ||
                row["Account ID"] ||
                "",
            customerName: row["Customer Name"]
        }),
        getEntityId: (data) => data.customerId || data.customerName,
        getUniqKey: (data, entityId) =>
            `Customer_${entityId}_REF_CUSTOMER_02_${entityId}`
    },
    REF_CUSTOMER_03: {
        type: "error",
        category: "REF",
        message:
            "'Delay Revenue until Payments' for this customer had been set to 'Yes' before and can not be set to 'Yes' again.",
        entityName: "Customer",
        collectData: ({ row }) => ({
            customerId:
                row["Customer Id"] ||
                row["Customer ID"] ||
                row["Account Id"] ||
                row["Account ID"] ||
                "",
            customerName: row["Customer Name"]
        }),
        getEntityId: (data) => data.customerId || data.customerName,
        getUniqKey: (data, entityId) =>
            `Customer_${entityId}_REF_CUSTOMER_03_${entityId}`
    },
    REF_CUSTOMER_04: {
        type: "error",
        category: "REF",
        message:
            "'Delay Revenue For Active Contracts' for this customer had been set before and can not be changed again.",
        entityName: "Customer",
        collectData: ({ row }) => ({
            customerId:
                row["Customer Id"] ||
                row["Customer ID"] ||
                row["Account Id"] ||
                row["Account ID"] ||
                "",
            customerName: row["Customer Name"],
            delayedRevenueForActiveContractsOnly:
                row["Delay Revenue For Active Contracts"],
            previousValueForDelayedRevenueForActiveContractOnly:
                row["Delay Revenue For Active Contracts"] == "Y" ? "N" : "Y"
        }),
        getEntityId: (data) => data.customerId || data.customerName,
        getUniqKey: (data, entityId) =>
            `Customer_${entityId}_REF_CUSTOMER_04_${entityId}`
    },
    REF_CUSTOMER_05: {
        type: "error",
        category: "REF",
        message:
            "Special characters are not allowed in Customer Id. Correct and reload it. ",
        entityName: "Customer",
        collectData: ({ row }) => ({
            customerId:
                row["Customer Id"] ||
                row["Customer ID"] ||
                row["Account Id"] ||
                row["Account ID"],
            customerName: row["Customer Name"]
        }),
        getEntityId: (data) => data.customerId || data.customerName,
        getUniqKey: (data, entityId) =>
            `Customer_${entityId}_REF_CUSTOMER_05_${entityId}`
    },
    REF_PRODUCT_01: {
        type: "error",
        category: "REF",
        message:
            "The product record we found doesn't have a product code on it.",
        entityName: "Product",
        collectData: ({ row }) => ({
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_01_${entityId}`
    },
    REF_PRODUCT_02: {
        type: "error",
        category: "REF",
        message:
            "The product record we found doesn't have a name on it. Correct and reload it.",
        entityName: "Product",
        collectData: ({ row }) => ({
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_02_${entityId}`
    },
    REF_PRODUCT_03: {
        type: "warning",
        category: "REF",
        message: "This product is missing a list price.",
        entityName: "Product",
        collectData: ({ row }) => ({
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_03_${entityId}`
    },
    REF_PRODUCT_04: {
        type: "warning",
        category: "REF",
        message: "The product is missing a price book identifier.",
        entityName: "Product",
        collectData: ({ row }) => ({
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_04_${entityId}`
    },
    REF_PRODUCT_05: {
        type: "error",
        category: "REF",
        message: "This product has an invalid type specified.",
        entityName: "Product",
        collectData: ({ row }) => ({
            entityType: row["Type"],
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_05_${entityId}`
    },
    REF_PRODUCT_06: {
        type: "error",
        category: "REF",
        message: "This product has a parent but is not a valid PO or BC type.",
        entityName: "Product",
        collectData: ({ row }) => ({
            entityType: row["Type"],
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_06_${entityId}`
    },
    REF_PRODUCT_07: {
        type: "error",
        category: "REF",
        message:
            "This offset period units are invalid. Must be months, years or quarters",
        entityName: "Product",
        collectData: ({ row }) => ({
            entityType: row["Type"],
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_07_${entityId}`
    },
    REF_PRODUCT_08: {
        type: "error",
        category: "REF",
        message:
            "The list price period units are invalid. Must be months, years or quarters",
        entityName: "Product",
        collectData: ({ row }) => ({
            entityType: row["Type"],
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_08_${entityId}`
    },
    REF_PRODUCT_09: {
        type: "error",
        category: "REF",
        message: "Duplicate product code found.",
        entityName: "Product",
        collectData: ({ row }) => ({
            entityType: row["Type"],
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_09_${entityId}`
    },
    REF_PRODUCT_10: {
        type: "error",
        category: "REF",
        message: "Product code can't contain spaces.",
        entityName: "Product",
        collectData: ({ row }) => ({
            entityType: row["Type"],
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_10_${entityId}`
    },
    REF_PRODUCT_11: {
        type: "error",
        category: "REF",
        message:
            "Product does not contain field set in ssp field. Please provide field set for ssp under settings.",
        entityName: "Product",
        collectData: ({ row }) => ({
            entityType: row["Type"],
            productCode: row["Product Code"],
            priceBookId: row["Pricebook Id"],
            clientProductId: row["Product Id"],
            productName: row["Product Name"]
        }),
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        getUniqKey: (data, entityId) =>
            `Product_${entityId}_REF_PRODUCT_11_${entityId}`
    },
    REF_JA_01: {
        type: "error",
        category: "REF",
        message: "The journal account is missing a identifier.",
        entityName: "JournalAccounts",
        collectData: ({ row }) => ({
            accountNumber: row["Account Number"],
            accountName: row["Account Name"],
            accountType: row["Account Type"]
        }),
        getEntityId: (data) =>
            data.accountNumber || data.accountName || data.accountType,
        getUniqKey: (data, entityId) =>
            `JournalAccounts_${entityId}_REF_JA_01_${entityId}`
    },
    REF_JA_02: {
        type: "error",
        category: "REF",
        message: "The journal account is missing a name.",
        entityName: "JournalAccounts",
        collectData: ({ row }) => ({
            accountNumber: row["Account Number"],
            accountName: row["Account Name"],
            accountType: row["Account Type"]
        }),
        getEntityId: (data) =>
            data.accountNumber || data.accountName || data.accountType,
        getUniqKey: (data, entityId) =>
            `JournalAccounts_${entityId}_REF_JA_02_${entityId}`
    },
    REF_SP_01: {
        type: "error",
        category: "REF",
        message:
            "We need the identifier for the salesperson (account owner) so we can associate with an order. It is missing.",
        entityName: "SalesPerson",
        collectData: ({ row }) => ({
            salesPersonId: row["Sales Person Id"],
            salesPersonName: row["Sales Person Name"]
        }),
        getEntityId: (data) => data.salesPersonId || data.salesPersonName,
        getUniqKey: (data, entityId) =>
            `Sales Person_${entityId}_REF_SP_01_${entityId}`
    },
    SO_4: {
        type: "warning",
        category: "orders",
        message:
            "Explicit overwrite sent for a sales orders that do not exist in the system. These orders will be treated as new.",
        specificMessage: (data) =>
            `Sales order [${data.salesOrderId}] does not exists in our system.`,
        collectData: ({ salesOrderId }) => ({ salesOrderId }),
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_SO_4_${entityId}`
    },
    SO_5: {
        type: "error",
        category: "orders",
        message: "We can't process salesOrder update because it has an error",
        specificMessage: (data) => {
            let message = `Sales Order [${data.salesOrderId}] can't be updated`;
            if (data.overwriteErrorMessage != undefined) {
                message += ` ${data.overwriteErrorMessage}`;
            }
            message += `. Original state of the Sales Order will remain intact.`;

            return message;
        },
        collectData: ({ overwriteErrorMessage, salesOrderId }) => ({
            overwriteErrorMessage,
            salesOrderId
        }),
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_SO_5_${entityId}`
    },
    SO_6: {
        type: "error",
        category: "orders",
        message:
            "You attempted to delete one or more sales order(s) in closed accounting period.",
        specificMessage: (data) => {
            let message = `You attempted to delete one or more sales order(s) in closed accounting period. Total count of order(s) which can't be deleted is [${
                data.undeletedOrderIds.length
            }]. Undeleted Order Id(s) ${JSON.stringify(
                data.undeletedOrderIds
            )}`;
            return message;
        },
        collectData: ({
            undeletedOrderIds,
            accountingPeriod,
            deletedOrderIds
        }) => ({
            undeletedOrderIds,
            accountingPeriod,
            deletedOrderIds
        }),
        entityName: "Sync",
        getEntityId: (data) => data.undeletedOrderIds.join(","),
        getUniqKey: (data, entityId) => `Sync_${entityId}_SO_6_${entityId}`
    },
    SO_7: {
        type: "error",
        category: "data",
        message: "An Un-expected error occurred while processing Sales Order.",
        specificMessage: (data) => {
            return `An Un-expected error occurred while processing Sales Order [${data.salesOrderId}]. Please contact Administrator!`;
        },
        collectData: ({ salesOrderId }) => ({ salesOrderId }),
        entityName: "Sync",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) => `Sync_${entityId}_SO_7_${entityId}`
    },
    SO_8: {
        type: "error",
        category: "data",
        message: "Something blew up while processing input data sheet/table.",
        specificMessage: ({ table }) => {
            return `An Un-expected error occurred while processing input data sheet/table [${table}]. Please contact Administrator!`;
        },
        collectData: ({ table }) => ({ table }),
        entityName: "Sync",
        getEntityId: (data) => data.table,
        getUniqKey: (data, entityId) => `Sync_${entityId}_SO_8_${entityId}`
    },
    SO_9: {
        type: "warning",
        category: "data",
        message:
            "Some orders have been manually updated and can't be updated because of a conflict.",
        specificMessage: (data) => {
            return `Order ${data &&
                data.salesOrderId} can't be updated automatically because auto sync disable.`;
        },
        collectData: ({ salesOrderId }) => ({ salesOrderId }),
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_SO_9_${entityId}`
    },
    SO_10: {
        type: "error",
        category: "data",
        message: "SaleOrder has more that 2000 order items.",
        specificMessage: (data) => {
            return `Order ${data &&
                data.salesOrderId} can't be added/updated as it has more than 2000 order items in it.`;
        },
        collectData: ({ salesOrderId }) => ({ salesOrderId }),
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_SO_10_${entityId}`
    },
    SO_11: {
        type: "error",
        category: "data",
        message: "SaleOrder's Term is more than 35 Years.",
        specificMessage: (data) => {
            return `Sales Order ${data &&
                data.salesOrderId} Term shouldn't be more than 35 Years.`;
        },
        collectData: ({ salesOrderId }) => ({ salesOrderId }),
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_SO_11_${entityId}`
    },
    SO_14: {
        type: "error",
        category: "data",
        message: "SalesOrder has no Order Item to track revenue.",
        specificMessage: (data) => {
            return `No Order Item found on SalesOrder ${data &&
                data.salesOrderId}
            with Product ${data &&
                data.productCodeToTrack} to track revenue for Order Item ${data &&
                data.salesOrderItemId}.`;
        },
        collectData: ({
            salesOrderId,
            salesOrderItemId,
            productCodeToTrack
        }) => ({ salesOrderId, salesOrderItemId, productCodeToTrack }),
        entityName: "OrderDetails",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `OrderDetails_${entityId}_SO_14_${entityId}`
    },
    SYSTEM: {
        type: "error",
        category: "system",
        message: "Something blew up with our flux capacitor! Please try again.",
        specificMessage: (data) => {
            return (
                (data.exception &&
                    `Unknown Error . ${
                        data.exception.message
                    }, Error :${data.exception.toString()}, Full Error :${JSON.stringify(
                        data.exception
                    )}`) ||
                "Unknown Error. Please contact administrator."
            );
        },
        collectData: ({ exception }) => ({ exception }),
        entityName: "SYSTEM",
        getEntityId: (data) => "SYSTEM",
        getUniqKey: (data, entityId) => "SYSTEM"
    },
    PENDING_DATA_2: {
        type: "error",
        category: "system",
        message: "Data could not be loaded, pending",
        entityName: "PendingData",
        getEntityId: (data) => "PendingData"
    },
    PENDING_DATA_1: {
        type: "error",
        category: "system",
        message: "Unable to save pending data to warehouse.",
        specificMessage: (data) => {
            return (
                (data.exception &&
                    `Unknown Error . ${
                        data.exception.message
                    }, Error :${data.exception.toString()}, Full Error :${JSON.stringify(
                        data.exception
                    )}`) ||
                "Unknown Error. Please contact administrator."
            );
        },
        collectData: ({ exception }) => ({ exception }),
        entityName: "PendingData",
        getEntityId: (data) => "PendingData",
        getUniqKey: (data, entityId) => "PENDING_DATA_1"
    },
    SSP_1: {
        type: "error",
        category: "system",
        message: "There was a problem evaluating your SSP link expression.",
        specificMessage: (data) => {
            return (
                `Error while evaluating SSP expression ${data.expression}. ` +
                (data.exception &&
                    `${
                        data.exception.message
                    }, Error : ${data.exception.toString()}, Full Error : ${JSON.stringify(
                        data.exception
                    )}`)
            );
        },
        collectData: ({ expression, exception }) => ({ expression, exception }),
        entityName: "Standalone Selling Price",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `Standalone Selling Price_${entityId}_SSP_1_${data.salesOrderItemId ||
                entityId}`
    },
    EXPENSE_1: {
        type: "error",
        category: "TRAN",
        message: "Invalid Expense Item.",
        collectData: ({ row }) =>
            Utils.prune({
                salesOrderId: row["Order Number"]
            }),
        specificMessage: (data) =>
            `Invalid expense data found for Order Number [${data.salesOrderId}]`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_1`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_1`
    },
    EXPENSE_2: {
        type: "error",
        category: "TRAN",
        message: "Expense Order Number and Reference Number is missing.",
        collectData: ({ row, rowNum }) =>
            Utils.prune({
                salesOrderId: row["Order Number"],
                referenceNumber: row["Reference Number"],
                productCode: row["Product Code"],
                expenseCode: row["Expense Code"],
                expenseType: row["Expense Type"],
                class: row["Class"],
                department: row["Department"],
                location: row["Location"],
                rowNum: rowNum
            }),
        specificMessage: (data) =>
            `Order Number and Reference Number is missing for Product Code [${data.productCode}], Class ${data.class}, Department ${data.department}, Location ${data.location}, RowNum [${data.rowNum}]`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_2`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_2`
    },
    EXPENSE_3: {
        type: "error",
        category: "TRAN",
        message: "Expense Date is missing or invalid.",
        collectData: ({ row, rowNum }) =>
            Utils.prune({
                salesOrderId: row["Order Number"],
                referenceNumber: row["Reference Number"],
                productCode: row["Product Code"],
                expenseCode: row["Expense Code"],
                expenseType: row["Expense Type"],
                class: row["Class"],
                department: row["Department"],
                location: row["Location"],
                expenseDate:
                    row["Original Expense Date"] || row["Expense Date"],
                rowNum: rowNum
            }),
        specificMessage: (data) =>
            `Expense Date is missing for Order Number [${data.salesOrderId}] Product Code [${data.productCode}], Class ${data.class}, Department ${data.department}, Location ${data.location}, RowNum [${data.rowNum}]`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_3`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_3`
    },
    EXPENSE_4: {
        type: "error",
        category: "TRAN",
        message: "Expense Code is missing.",
        collectData: ({ row, rowNum }) =>
            Utils.prune({
                salesOrderId: row["Order Number"],
                referenceNumber: row["Reference Number"],
                productCode: row["Product Code"],
                expenseCode: row["Expense Code"],
                expenseType: row["Expense Type"],
                class: row["Class"],
                department: row["Department"],
                location: row["Location"],
                rowNum: rowNum
            }),
        specificMessage: (data) =>
            `Expense Code is missing for Order Number [${data.salesOrderId}] Product Code [${data.productCode}], Class ${data.class}, Department ${data.department}, Location ${data.location}, RowNum [${data.rowNum}]`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_4`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_4`
    },
    EXPENSE_5: {
        type: "error",
        category: "TRAN",
        message: "Missing or bad Expense Amount for these items.",
        collectData: ({ row, rowNum }) =>
            Utils.prune({
                salesOrderId: row["Order Number"],
                referenceNumber: row["Reference Number"],
                productCode: row["Product Code"],
                expenseCode: row["Expense Code"],
                expenseType: row["Expense Type"],
                class: row["Class"],
                department: row["Department"],
                location: row["Location"],
                rowNum: rowNum
            }),
        specificMessage: (data) =>
            `Expense Code is missing for Order Number [${data.salesOrderId}] Product Code [${data.productCode}], Class ${data.class}, Department ${data.department}, Location ${data.location}, RowNum [${data.rowNum}]`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_5`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_5`
    },
    EXPENSE_6: {
        type: "error",
        category: "TRAN",
        message:
            "No matching product code found in the sales order for expense line item.",
        collectData: ({ expenseItem }) => {
            let toReturn = {
                salesOrderId: expenseItem.salesOrderId,
                referenceNumber: expenseItem.referenceNumber || "",
                productCode: expenseItem.productCode || "",
                expenseCode: expenseItem.expenseCode || "",
                expenseType: expenseItem.expenseType || "",
                class: expenseItem.class || "",
                department: expenseItem.department || "",
                location: expenseItem.location || ""
            };

            if (
                expenseItem &&
                expenseItem.attributes &&
                Object.keys(expenseItem.attributes).length > 0
            ) {
                toReturn = Object.assign(toReturn, expenseItem.attributes);
            }
            return Utils.prune(toReturn);
        },
        specificMessage: (data) =>
            `No matching product code found for Order Number [${data.salesOrderId}] Product Code [${data.productCode}], Class ${data.class}, Department ${data.department}, Location ${data.location}`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_6`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_6`
    },
    EXPENSE_7: {
        type: "error",
        category: "TRAN",
        message: "Was unable to find the expense rule for these items.",
        specificMessage: (data) =>
            `Was unable to find expense rule for product [${data.productCode}] in expense item [${data.expenseCode}].`,
        collectData: ({ expenseItem }) => {
            let toReturn = {
                salesOrderId: expenseItem.salesOrderId,
                referenceNumber: expenseItem.referenceNumber || "",
                productCode: expenseItem.productCode || "",
                expenseCode: expenseItem.expenseCode || "",
                expenseType: expenseItem.expenseType || "",
                class: expenseItem.class || "",
                department: expenseItem.department || "",
                location: expenseItem.location || ""
            };
            if (
                expenseItem.attributes &&
                Object.keys(expenseItem.attributes).length > 0
            ) {
                toReturn = Object.assign(toReturn, expenseItem.attributes);
            }
            return Utils.prune(toReturn);
        },
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_7`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_7`
    },

    EXPENSE_8: {
        type: "error",
        category: "TRAN",
        message:
            "We can't process expense because it has duplicate expense items",
        specificMessage: (data) =>
            `Expense [${data.salesOrderId}] has product [${data.productCode}] expense code [${data.expenseCode}] which is a duplicate entry. RowNum: [${data.rowNum}]`,
        collectData: ({ row }) =>
            Utils.prune({
                salesOrderId: row["Order Number"],
                expenseCode: row["Expense Code"]
            }),
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_8`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_8`
    },

    EXPENSE_9: {
        type: "error",
        category: "TRAN",
        message:
            "We can't process expenses because expense feature is disabled.",
        specificMessage: (data) =>
            "We can't process expenses because expense feature is disabled.",
        collectData: ({ expenseItem }) => {
            return {};
        },
        entityName: "Expense",
        getEntityId: (data) => `properties-expense-enabled`,
        getUniqKey: (data, entityId) => `EXPENSE_9`
    },
    EXPENSE_10: {
        type: "error",
        category: "TRAN",
        message:
            "No matching product code found in the system for expense line item.",
        collectData: ({ row }) => {
            let toReturn = {
                salesOrderId: row["Order Number"],
                referenceNumber: row["Reference Number"] || "",
                productCode: row["Product Code"] || "",
                expenseCode: row["Expense Code"] || "",
                expenseType: row["Expense Type"] || "",
                class: row["Class"] || "",
                department: row["Department"] || "",
                location: row["Location"] || ""
            };

            return Utils.prune(toReturn);
        },
        specificMessage: (data) =>
            `No matching product code found in the system Order Number [${data.salesOrderId}] Product Code [${data.productCode}], Class ${data.class}, Department ${data.department}, Location ${data.location}`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_10`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_10`
    },
    EXPENSE_11: {
        type: "error",
        category: "data",
        message: "Expense has more that 10 expense items.",
        collectData: ({ row }) =>
            Utils.prune({
                salesOrderId: row["Order Number"],
                expenseCode: row["Expense Code"] || ""
            }),
        specificMessage: (data) =>
            `Expense Code ${data.expenseCode} for Order ${data.salesOrderId} can't be added/updated as it has more than 10 expense items in it.`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_11`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_11`
    },
    EXPENSE_12: {
        type: "error",
        category: "data",
        message: "Expense has more than 50 expense items.",
        collectData: ({ row }) =>
            Utils.prune({
                salesOrderId: row["Order Number"],
                expenseCode: row["Expense Code"] || ""
            }),
        specificMessage: (data) =>
            `Expense Code ${data.expenseCode} for Order ${data.salesOrderId} can't be added/updated as it has more than 50 expense items in it.`,
        entityName: "Expense",
        getEntityId: (data) => `${data.salesOrderId}_EXPENSE_12`,
        getUniqKey: (data, entityId) => `${data.salesOrderId}_EXPENSE_12`
    },
    EXPENSE_13: {
        type: "error",
        category: "TRAN",
        message: "Amortization Start Date is incorrect.",
        entityName: "Expense",
        getEntityId: (data) => {
            return data && data.row ? data.row["Amortization Start Date"] : "";
        }
    },
    EXPENSE_14: {
        type: "error",
        category: "TRAN",
        message: "Amortization End Date is incorrect.",
        entityName: "Expense",
        getEntityId: (data) => {
            return data && data.row ? data.row["Amortization End Date"] : "";
        }
    },
    SO_12: {
        type: "error",
        category: "data",
        message:
            "Termination record discarded as no matching sales order was found.",
        specificMessage: (data) => {
            return `Sales Order ${data &&
                data.salesOrderId} not found in the system.`;
        },
        collectData: ({ salesOrderId }) => ({ salesOrderId }),
        entityName: "Terminations",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `Terminations_${entityId}_SO_12_${entityId}`
    },
    SO_13: {
        type: "error",
        category: "data",
        message: "Invalid termination date.",
        specificMessage: (data) => {
            return `Sales Order ${data &&
                data.salesOrderId} termination record was discarded.`;
        },
        collectData: ({ salesOrderId }) => ({ salesOrderId }),
        entityName: "Terminations",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `Terminations_${entityId}_SO_13_${entityId}`
    },
    PSD_01: {
        type: "error",
        category: "data",
        message: "Service Delivery in closed period can not be overridden.",
        specificMessage: (data) => {
            return `Sales Order ${data &&
                data.salesOrderId} PSD record discarded.`;
        },
        collectData: ({ salesOrderId, logDate, unitsDelivered }) => ({
            salesOrderId,
            logDate,
            unitsDelivered
        }),
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `ServiceDelivery${entityId}_PSD_01_${entityId}`
    },
    PSD_02: {
        type: "error",
        category: "data",
        message: "Duplicate Service Delivery record.",
        specificMessage: (data) => {
            return `Sales Order ${data &&
                data.salesOrderId} PSD record discarded.`;
        },
        collectData: ({ salesOrderId, logDate, unitsDelivered }) => ({
            salesOrderId,
            logDate,
            unitsDelivered
        }),
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `ServiceDelivery${entityId}_PSD_02_${entityId}`
    },
    PSD_03: {
        type: "error",
        category: "data",
        message: "Invalid Log Date",
        specificMessage: (data) =>
            `Service Delivery log discarded on line [${data.rowNum}] due to invalid Log Date.`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${entityId}_PSD_03_${entityId}`
    },
    PSD_04: {
        type: "error",
        category: "data",
        message: "Invalid Service End Date",
        specificMessage: (data) =>
            `Service Delivery log discarded on line [${data.rowNum}] due to invalid Service End Date.`,
        entityName: "ServiceDelivery",
        getEntityId: (data) => data.salesOrderId,
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${entityId}_PSD_04_${entityId}`
    },
    PSD_05: {
        type: "warning",
        category: "data",
        message:
            "Service Delivery log(s) will be ignored if the log date is before the modification date. Add service delivery log(s) belonging to pre-modification contract before modifying the contract.",
        entityName: "ServiceDelivery",
        specificMessage: (data) =>
            `Service Delivery log discarded on line [${data.rowNum}] as the log date [${data.logDate}] is before the modification date.`,
        collectData: ({ id, serviceDeliveryId, salesOrderId, logDate }) => ({
            serviceDeliveryId: serviceDeliveryId || id,
            salesOrderId,
            logDate
        }),
        getEntityId: (data) => {
            return data.serviceDeliveryId || data.salesOrderId;
        },
        getUniqKey: (data, entityId) =>
            `ServiceDelivery_${data.serviceDeliveryId}_PSD_05_${data.salesOrderId}`
    },
    INVALID_CURRENCY_ORDER: {
        type: "error",
        category: "data",
        entityName: "OrderDetails",
        message:
            "Transaction currency doesn't match non-multicurrency configured home currency.",
        specificMessage: (data) => {
            return (
                `Transaction currency [${(data && data.currency) || "N/A"}] ` +
                `doesn't match non-multicurrency configured home currency.`
            );
        },
        getEntityId: (data) => data.salesOrderId,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            currency: row["Currency"]
        })
    },
    INVALID_CURRENCY_BS: {
        type: "error",
        category: "data",
        entityName: "BillingSchedule",
        message:
            "Transaction currency doesn't match non-multicurrency configured home currency.",
        specificMessage: (data) => {
            return (
                `Transaction currency [${(data && data.currency) || "N/A"}] ` +
                `doesn't match non-multicurrency configured home currency.`
            );
        },
        getEntityId: (data) => data.salesOrderId,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            currency: row["Currency"]
        })
    },
    INVALID_CURRENCY_CUST: {
        type: "error",
        category: "data",
        entityName: "Customer",
        message:
            "Customer currency doesn't match non-multicurrency configured home currency.",
        specificMessage: (data) => {
            return (
                `Customer [${data && data.customerId}] currency ` +
                `[${(data && data.currency) || "N/A"}] ` +
                `doesn't match non-multicurrency configured home currency.`
            );
        },
        getEntityId: (data) => data.customerId || data.customerName,
        collectData: ({ row }) => ({
            customerId:
                row["Customer Id"] ||
                row["Customer ID"] ||
                row["Account Id"] ||
                row["Account ID"],
            currency: row["Currency"]
        })
    },
    INVALID_CURRENCY_PROD: {
        type: "error",
        category: "data",
        entityName: "Product",
        message:
            "Product currency doesn't match non-multicurrency configured home currency.",
        specificMessage: (data) => {
            return (
                `Product [${data && data.productCode}] currency ` +
                `[${(data && data.currency) || "N/A"}] ` +
                `doesn't match non-multicurrency configured home currency.`
            );
        },
        getEntityId: (data) =>
            data.productCode ||
            data.priceBookId ||
            data.clientProductId ||
            data.productName,
        collectData: ({ row }) => ({
            productCode: row["Product Code"],
            currency: row["Currency"]
        })
    },
    INVALID_CURRENCY_TAX: {
        type: "error",
        category: "data",
        entityName: "Tax",
        message:
            "Tax currency doesn't match non-multicurrency configured home currency.",
        specificMessage: (data) => {
            return (
                `Tax record [` +
                `${data && data.salesOrderId} : ${data &&
                    data.salesOrderItemId}` +
                `] currency [${(data && data.currency) || "N/A"}] ` +
                `doesn't match non-multicurrency configured home currency.`
            );
        },
        getEntityId: (data) => data.salesOrderId,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"],
            currency: row["Currency"]
        })
    },
    TAX_BILLING_DATE: {
        type: "error",
        category: "data",
        entityName: "Tax",
        message: "A valid tax billing date is required.",
        specificMessage: (data) => {
            return (
                `Tax record [` +
                `${data && data.salesOrderId} : ${data &&
                    data.salesOrderItemId}` +
                `] requires a valid billing date [${(data &&
                    data.billingDate) ||
                    "N/A"}].`
            );
        },
        getEntityId: (data) => data.salesOrderId,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"],
            billingDate: row["Billing Date"]
        })
    },
    TAX_CURRENCY: {
        type: "error",
        category: "data",
        entityName: "Tax",
        message: "A valid tax currency is required.",
        specificMessage: (data) => {
            return (
                `Tax record [` +
                `${data && data.salesOrderId} : ${data &&
                    data.salesOrderItemId}` +
                `] requires a valid currency [${(data && data.currency) ||
                    "N/A"}].`
            );
        },
        getEntityId: (data) => data.salesOrderId,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"],
            currency: row["Currency"]
        })
    },
    TAX_AMOUNT: {
        type: "error",
        category: "data",
        entityName: "Tax",
        message: "A valid tax amount is required.",
        specificMessage: (data) => {
            return (
                `Tax record [` +
                `${data && data.salesOrderId} : ${data &&
                    data.salesOrderItemId}` +
                `] requires a valid amount [${(data && data.amount) || "N/A"}].`
            );
        },
        getEntityId: (data) => data.salesOrderId,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"],
            amount: row["Tax Amount"]
        })
    },
    TAX_INFO: {
        type: "error",
        category: "data",
        entityName: "Tax",
        message: "A tax info of valid json type is required.",
        specificMessage: (data) => {
            return (
                `Tax record [` +
                `${data && data.salesOrderId} : ${data &&
                    data.salesOrderItemId}` +
                `] doesn't have a valid json value [${(data && data.value) ||
                    "N/A"}].`
            );
        },
        getEntityId: (data) => data.salesOrderId,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"],
            salesOrderItemId: row["Order Item Number"],
            value: row["Tax Info"]
        })
    },
    SO_NOT_FOUND: {
        type: "error",
        category: "orders",
        entityName: "OrderDetails",
        message: "Sales order is not found",
        specificMessage: (data) => {
            return `Sales order [${data && data.salesOrderId}] is not found.`;
        },
        getEntityId: (data) => data.salesOrderId,
        collectData: ({ row }) => ({
            salesOrderId: row["Order Number"]
        })
    }
};

const collectData = (errorCode, data) => {
    let message = Messages[errorCode];

    let {
        salesOrderId,
        salesOrder,
        salesOrderItem,
        revenueArrangementItem,
        rowNum,
        row,
        sheet,
        filename
    } = data;

    let product = salesOrderItem && salesOrderItem.product;
    let customer = salesOrder && salesOrder.customer;

    let toReturn = Object.assign(
        {
            salesOrderId:
                salesOrderId ||
                (salesOrder && salesOrder.id) ||
                (salesOrderItem && salesOrderItem.salesOrderId),
            salesOrderItemId:
                salesOrderItem && (salesOrderItem.rootId || salesOrderItem.id),
            revenueArrangementId:
                revenueArrangementItem &&
                revenueArrangementItem.revenueArrangementId,
            // factAmount can be overrided within collectData of each error.
            factAmount:
                (salesOrderItem && salesOrderItem.extendedSalePrice) || 0,
            product: product && product.name,
            productCode: product && product.code,
            customer: customer && customer.name,
            customerId:
                (customer && customer.id) ||
                (salesOrder && salesOrder.customerId),
            productId:
                (product && product.id) ||
                (salesOrderItem && salesOrderItem.productId),
            rowNum,
            row,
            sheet,
            filename
        },
        message.collectData && message.collectData(data)
    );

    return toReturn;
};

let errorOrder = ["Contract", "Product", "Customer", "Attributes", "Others"];

const getAllGroups = (allCustomFieldConfigs) => {
    let toReturn = {
        salesOrderId: { Group: "Contract", order: 1 },
        salesOrderItemId: { Group: "Contract", order: 1 },
        product: { Group: "Product", order: 2 },
        productCode: { Group: "Product", order: 2 },
        customer: { Group: "Customer", order: 3 }
    };

    if (allCustomFieldConfigs) {
        const {
            customFieldsConfig,
            bsCustomFieldConfig,
            psCustomFieldConfig
        } = allCustomFieldConfigs;

        const customFields = {};

        let index = 1;
        customFieldsConfig &&
            Object.values(customFieldsConfig.value).forEach((customField) => {
                if (customField.type !== "richText") {
                    customFields[customField.name] = {
                        Group: "Attributes",
                        order: index
                    };
                    index += 1;
                }
            });

        bsCustomFieldConfig &&
            Object.values(bsCustomFieldConfig.value).forEach((customField) => {
                if (
                    !customFields[customField.name] &&
                    customField.type !== "richText"
                ) {
                    customFields[customField.name] = {
                        Group: "Attributes",
                        order: index
                    };
                    index += 1;
                }
            });

        psCustomFieldConfig &&
            Object.values(psCustomFieldConfig.value).forEach((customField) => {
                if (
                    !customFields[customField.name] &&
                    customField.type !== "richText"
                ) {
                    customFields[customField.name] = {
                        Group: "Attributes",
                        order: index
                    };
                    index += 1;
                }
            });

        toReturn = Object.assign(toReturn, customFields);
    }

    return toReturn;
};

const errorTypes = {
    error: "Error",
    warning: "Warning"
};

export const errToOutputErr = (clientId, jobId, err) => {
    const JobId = (err.row && err.row.JobId) || jobId;
    const errorCode = Messages[err.code] || {
        entityName: "Unknown",
        getEntityId: () => "Unknown"
    };
    const Entity = err.sheet || errorCode.entityName;
    const EntityId = errorCode.getEntityId(err);

    const d = {
        OrgId_Entity_EntityId: `${clientId}:${Entity}:${EntityId}`,
        OrgId: clientId,
        JobId,
        Entity,
        EntityId,
        ErrorCode: err.code,
        LastModifiedDate: new Date().toISOString().substring(0, 10)
    };

    return d;
};

export const errToInputErr = (clientId, jobId, err) => {
    const JobId = jobId;
    const Entity = (err.row && err.row.sheet) || err.sheet;
    const RowId = (err.row && err.row["__rowNum__"]) || err.Id || err.rowNum;
    const errorCode = Messages[err.code] || {
        entityName: "Unknown",
        getEntityId: () => "Unknown"
    };
    const EntityId = errorCode.getEntityId
        ? errorCode.getEntityId(err)
        : "Unknown";

    let toReturn = {
        OrgId: clientId,
        JobId,
        Entity,
        EntityId,
        RowId,
        ErrorCode: err.code,
        LastModifiedDate: Utils.formatDate(new Date())
    };

    return toReturn;
};

export const convertErrorsForUI = (clientId, jobId, err) => {
    const errorCode = Messages[err.code] || {
        entityName: "Unknown",
        getEntityId: () => "Unknown"
    };

    const error = {
        Severity: errorCode.type,
        Message: errorCode.message,
        ErrorCode: err.code
    };

    if (err.serviceDeliveryId) {
        error["ServiceDeliveryId"] = err.serviceDeliveryId;
    }

    return error;
};

export const convertToErrorsEntity = (errors, customFieldsConfig) => {
    const allGroups = getAllGroups(customFieldsConfig);

    const toReturn = [];
    for (let error of errors) {
        const entityId =
            Messages[error.code].getEntityId &&
            Messages[error.code].getEntityId(error);
        const entityName = Messages[error.code].entityName;
        let uniqKey =
            (Messages[error.code].getUniqKey &&
                Messages[error.code].getUniqKey(error, entityId)) ||
            "";

        const toPush = {
            entityName,
            entityId,
            fact: error.factAmount,
            entityMetaData: [],
            entityStatus: "New",
            uniqKey
        };

        let errorType = Messages[error.code].type;
        toPush.severity = errorTypes[errorType] || errorType;
        toPush.message =
            (Messages[error.code].specificMessage &&
                Messages[error.code].specificMessage(error)) ||
            Messages[error.code].message;

        const errorKeys = Object.keys(error);

        toPush.entityMetaData.push({
            Group: "Others",
            Name: "Code",
            Value: error.code,
            order: 0
        });

        let othersOrder = 1;
        for (let key of errorKeys) {
            const value = error[key];

            if (
                !value ||
                Utils.isObject(value) ||
                ["factAmount", "code", "uniqKey"].includes(key)
            ) {
                continue;
            }

            let group = allGroups[key];

            if (!group) {
                group = { Group: "Others", order: othersOrder };
                othersOrder += 1;
            }

            group = Utils.cloneDeep(group);
            group.Name = Utils.convertToNormalCaseFromCamelCase(key);
            group.Value = error[key];
            toPush.entityMetaData.push(group);
        }

        toPush.entityMetaData &&
            toPush.entityMetaData.sort(
                Comparator.getComparator(
                    [
                        (element) => errorOrder.indexOf(element["Group"]),
                        "order"
                    ],
                    [Comparator.forType("string"), Comparator.forType("number")]
                )
            );

        toReturn.push(toPush);
    }

    return toReturn;
};

export const Codes = {};
Object.keys(Messages).forEach((code) => (Codes[code] = code));

/**
 * Method will generate all SQL for inserting error codes into WH
 *
 * @returns {Array} of REPLACE INTO Sql Statements
 */
export const getAllErrorCodeSqls = () => {
    const allErrorSqls = [];
    Object.keys(Messages).forEach((code) => {
        const msg = Messages[code];
        allErrorSqls.push(`
            REPLACE INTO ERRORCODES
            (ErrorCode, Message, Severity, EntityName, LastModifiedDate)
            VALUES
            ('${code}', ${Utils.sqlEscapeId(msg.message)}, '${msg.type}', '${
            msg.entityName
        }', NOW())`);
    });

    return allErrorSqls;
};

/**
 * Determine if an order has an error
 * @param salesOrder
 */
export const orderHasError = (salesOrder) => {
    if (!salesOrder || !salesOrder.errors?.length) return false;
    salesOrder.errors = salesOrder.errors.filter((err) => err);

    return (
        salesOrder.errors.find(
            (err) =>
                Messages[err.code].type === "error" &&
                !err.code.startsWith("EXPENSE_")
        ) !== undefined
    );
};

export function Collection(errors) {
    this.errors = errors || [];
    this.orderIndex = new Set();

    //indices to count errors per sheet, file
    this.inputFileErrors = {};
}

Collection.prototype.getSheetErrorCounts = function(filename, sheet) {
    const key = `${filename}:${sheet}`;
    return (this.inputFileErrors[key] && this.inputFileErrors[key].size) || 0;
};

Collection.prototype.add = function(code, data = {}) {
    if (!Messages[code]) throw new Error("Unknown errorCode [" + code + "]");

    const err = collectData(code, data);
    this.errors.push({
        code,
        ...err
    });

    if (Messages[code].type == "error" && err.salesOrderId) {
        this.orderIndex.add(err.salesOrderId);
    }

    if (data && data.rowNum) {
        const { rowNum, sheet, filename } = data;

        const key = `${filename}:${sheet}`;
        if (!this.inputFileErrors[key]) this.inputFileErrors[key] = new Set();

        this.inputFileErrors[key].add(rowNum);
    }
    //if this is a data error from an input file count one error per row
};

Collection.prototype.hasExpenseError = function() {
    if (this.errors.length == 0) return false;

    return (
        this.errors.find(
            (error) =>
                Messages[error.code].type == "error" &&
                error.code.startsWith("EXPENSE_")
        ) != undefined
    );
};

Collection.prototype.hasError = function() {
    if (this.errors.length == 0) return false;

    return (
        this.errors.find(
            (error) =>
                Messages[error.code].type == "error" &&
                !error.code.startsWith("EXPENSE_")
        ) != undefined
    );
};

Collection.prototype.hasErrorForOrder = function(salesOrderId, errorCode) {
    if (this.errors.length === 0 || this.orderIndex.size === 0) return false;

    if (errorCode)
        return (
            this.errors.find(
                (error) =>
                    error.code == errorCode &&
                    error.salesOrderId == salesOrderId
            ) != undefined
        );

    return this.orderIndex.has(salesOrderId);
};

Collection.prototype.length = function() {
    return this.errors.length;
};

Collection.prototype.getErrorsObj = function() {
    return this.errors;
};

/**
 * Return all the error messages organized by error codes.
 * {
 *  code: [{code: 'X', data: [{code: code, salesOrder: {object}, salesOrderItem: {object}},... ]
 * }
 */
Collection.prototype.getAll = function(options) {
    const messages = {};

    // By default transactions are generated.
    options = Object.assign(
        {
            maxPerType: 5
        },
        options
    );

    const { maxPerType } = options;

    this.errors.forEach((err) => {
        if (!messages[err.code]) {
            messages[err.code] = {
                message: Messages[err.code],
                count: 0,
                data: []
            };
        }

        if (messages[err.code].data.length < maxPerType) {
            messages[err.code].data.push(err);
        }

        messages[err.code].count++;
    });

    return messages;
};
